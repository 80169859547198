
import React from 'react';
import Safe from './Safe';
import { css } from '../util/pagetools';

export default function PageSection(props) {
  const cfg = props.config;

  return (
    <div id={cfg.id} className={css('basic-section', cfg.theme)}>
      <div className="container-fluid">
        <div className="row">
        <div className={css('col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2', cfg.id)}>
            <h1 className={css('section-header', cfg.headerclass)}>
              <Safe type="span" content={cfg.header || cfg.title} />
            </h1>
            {renderParagraphs(cfg.text || cfg.data, cfg.theme, cfg.displayAs)}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, theme, paragraphType) {
  if (!Array.isArray(parags)) return <p>?</p>;
  const collectionType = (typeof parags[0]);
  const styling = theme || 'text-black';
  // Plain strings are to render as simple paragraphs
  if (collectionType==='string') {
    if (paragraphType==='list') {
      return <ul>{parags.map((item, index) => <li key={index} className={styling}>{item}</li>)}</ul>;
    }
    else if (paragraphType==='faq') { return renderOrderedFaq(parags, styling); }
    else if (paragraphType==='ufaq') { return renderUnorderedFaq(parags, styling); }
    return parags.map((item, index) => <Safe key={index} className={styling} type="p" content={item} />);
  }
  return parags.reduce((output, paragraph, index) => {
    if (paragraph.items) {
      // Render a list
      output.push(<ul key={index}>{paragraph.items.map((item, idx) => <Safe key={`li_${idx}`} className={styling} type="li" content={item} />)}</ul>);
    } else {
      const pStyle = (paragraph.styling ? `${styling} ${paragraph.styling}` : styling);
      // Check for delimiter characters which indicate embedded links
      if (paragraph.content && paragraph.content.indexOf('@')>0) {
        parseLinks(paragraph.content, '|').forEach(item => output.push(item));
      } else {
        // Otherwise render a paragraph
        output.push(<Safe key={index} type="p" className={pStyle} content={paragraph.content} />);
      }
    }
    return output;
    }, []);
}

/* This function will parse links according to this format:
   Text text... @url|label@ text text 
   @ delimits the link
   | delimits url from link text
*/
function parseLinks(text, delim) {
  const parts = text.split('@');
  return parts.reduce((result, part, index) => {
    if (index%2===0) result.push(<Safe key={`p-${index}`} type="span" content={part} />);
    else {
      const [url, label] = part.split(delim);
      result.push((part.charAt(0)==='#' ? <Safe key={`p-${index}`} type="Link" to={url} content={label} /> : <Safe key={`p-${index}`} type="a" href={url} content={label} />));
    }
    return result;
  }, []);
}

function renderUnorderedFaq(parags, styling) {
  return parags.map((item, index) => {
    const [q, ans] = item.split('?');
    return (
      <div key={index}>
        <p className={`faq ${styling}`}>{q+'?'}</p>
        <p className={styling}>{ans}</p>
      </div>
    );
  });
}

function renderOrderedFaq(parags, styling) {
  return (
    <ol className="faqlist">
      {parags.map((item, index) => {
        const [q, ans] = item.split('?');
        return (
          <li key={index}>
            <p className={`faq`}>{q+'?'}</p>
            {faqAnswer(ans, styling)}
          </li>
        );
      })}
    </ol>
  );
}

// Support multiple paragraphs by parsing by delimiter (semi-colon).
function faqAnswer(text, styling) {
  if (text.indexOf(';')>=0) return text.split(';').map((parag,idx) => <p key={`faqp-${idx}`} className={styling}>{parag}</p>);
  return <p className={styling}>{text}</p>;
}
