/**
 * Candidate information form.
 */

import React, { useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import PlaceInput from './PlaceInput';
import Dropdown from './Dropdown';
import { getCookie } from '../util/pagetools';

// Create a forward ref to enable accessing ref for focusing
const PhoneInput = React.forwardRef((props, ref) => (
  <InputMask
    mask="(999) 999-9999"
    name={props.name}
    id={props.name}
    value={props.value}
    className="form-control"
    aria-required="true"
    ref={ref}
    onChange={props.onChange}
    onBlur={props.onChange}>
  </InputMask>
));

const formValidators = {
  firstname: /(.|\s)*\S(.|\s)*/,
  lastname: /(.|\s)*\S(.|\s)*/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  autocomplete: /(.|\s)*\S(.|\s)*/
};

const reqIndicator = <span title="This field is required.">*</span>;
const UTM = 'UTM_INFO';
let formInteracted = false;

export default function PatientForm(props) {
  const site = props.sitedata;
  const btnTheme = 'std-btn btn-white-on-dark';
  const formTheme = `patient-form${props.isQualified?'':' dq'}`;
  const errorTheme = props.errortheme || 'text-red';
  const [patientInfo, setPatientInfo] = useState({
    firstname: '',
    lastname: '',
    gender: '',
    phone: '',
    email: '',
    howDidYouHearAboutUs: '',
    qualified: (props.isQualified ? 1 : 0),
    language: site.language
  });

  const [errors, setErrors] = useState({
    firstname: true,
    lastname: true,
    phone: true,
    email: true,
    autocomplete: true
  });
  const [userAddress, setUserAddress] = useState({});
  // Define field refs for setting focus
  const fieldRefs = {
    firstname: useRef(null),
    lastname: useRef(null),
    phone: useRef(),
    email: useRef(null),
    autocomplete: useRef(null)
  };

  function onSubmit(e) {
    e.preventDefault();
    formInteracted = true;
    // Validate here!
    const inValidFields = validateForm();
    if (inValidFields.length>0) {
      // Focus on first invalid field
      const firstError = fieldRefs[inValidFields[0]].current;
      if (firstError.focus) firstError.focus();
      else if (firstError.getInputDOMNode) firstError.getInputDOMNode().focus();
      return;
    }
    const payload = Object.assign({}, patientInfo, userAddress)
    if (props.onSubmit) {
      const generalInfo = {
        utm: getCookie(UTM),
        site: site.siteurl
      };
      props.onSubmit(Object.assign(payload, generalInfo));
      formInteracted = false; // reset interaction flag
    }
  }

  function handleInputChange(event) {
    setPatientInfo({
      ...patientInfo,
      [event.target.name]: event.target.value
    });
  }

  function validateInput(event) {
    setPatientInfo({
      ...patientInfo,
      [event.target.name]: event.target.value
    });
    const validator = formValidators[event.target.name];
    const isFieldOk = (validator ? validator.test(event.target.value) : true);
    setErrors({ ...errors, [event.target.name]: !isFieldOk });
  }

  // Runs onChange and onBlur for address field
  function addressSetter(data) { setUserAddress(data); }

  function showError(field) { return (errors[field] && formInteracted); }

  function validateForm() {
    // Collect an array of field names and their statuses
    const updatedErrors = Object.keys(formValidators).reduce((status, field) => {
      const fieldvalue = (field==='autocomplete' ? (userAddress?userAddress.full:'') : patientInfo[field]);
      const validator = formValidators[field];
      const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
      // console.log(`VF: ${field} = "${fieldvalue}" ..`, isFieldOk);
      status[field] = !isFieldOk;
      return status;
      }, {});
    setErrors(updatedErrors);
    // Return only those items with errors
    return Object.keys(updatedErrors).filter(key => updatedErrors[key]);
  }

  return (
    <form className={formTheme}>
      <div className="row">
        <div className="col-sm-6 form-group">
          <label htmlFor="firstname">{site.patientFields.firstname} {reqIndicator}</label>
          <input type="text" name="firstname" id="firstname" className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput} value={patientInfo.firstname} ref={fieldRefs.firstname} />
          <p className={`${errorTheme} ${showError('firstname') ? 'vis' : 'hid'}`}>First name must not be blank</p>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="lastname">{site.patientFields.lastname} {reqIndicator}</label>
          <input type="text" name="lastname" id="lastname" className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput}value={patientInfo.lastname} ref={fieldRefs.lastname} />
          <p className={`${errorTheme} ${showError('lastname') ? 'vis' : 'hid'}`}>Last name must not be blank</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 form-group">
          <label htmlFor="phone">{site.patientFields.phone} {reqIndicator}</label>
          <PhoneInput name="phone" onChange={validateInput} value={patientInfo.phone} ref={fieldRefs.phone} />
          <p className={`${errorTheme} ${showError('phone') ? 'vis' : 'hid'}`}>Please enter a valid phone number</p>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="email">{site.patientFields.email} {reqIndicator}</label>
          <input type="text" name="email" id="email" className="form-control" aria-required="true"
                 onChange={validateInput} value={patientInfo.email} ref={fieldRefs.email}/>
          <p className={`${errorTheme} ${showError('email') ? 'vis' : 'hid'}`}>Please enter a valid email</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor="autocomplete">{site.patientFields.address} {reqIndicator}</label>
          <PlaceInput id="autocomplete" onChangeBlur={addressSetter} focusRef={fieldRefs.autocomplete} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <Dropdown name="howDidYouHearAboutUs"
              items={site.patientFields.referredby}
              defaultItem={site.patientFields.referredbyDefault}
              ddStyle="dropdown form-control"
              responder={handleInputChange} />
          </div>
        </div>
      </div>
      <div className="btn-container">
        <button className={btnTheme} onClick={onSubmit}>{props.formAction}</button>
      </div>
    </form>
  );
};
