/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from './Banner';
import Nav from './NavToggleFlex';
import FixedPanel from './FixedPanel';
import PageSection from './PageSection';
import Screener from './Screener';
import Footer from './FooterSimple';
import { makeTopLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function Layout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = makeTopLinks(cfg);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const {title, subtitle, startstudy, banner} = cfg;

  const allSections =  cfg.sections.map(section => <PageSection key={section.id} config={section} />);
  const imgPanel = <div className="-fluid" key="imgPanel">
    <StaticImage src='../images/content_1.jpg' alt="" className="mx-auto" />
  </div>;
  const imgPanel2 = <div className="-fluid" key="imgPanel2">
    <StaticImage src='../images/content_2.jpg' alt="" className="mx-auto" />
  </div>;
  allSections.splice(1, 0, imgPanel);
  allSections.splice(5, 0, imgPanel2);
  return (
    <div id="page">
      <div id="layout-root">
        <header>
          <Nav toplinks={toplinks}
               sitename={cfg.sitename}
               theme={cfg.navbar.theme}
               ctx={props.ctx}
               currentpath={props.currentpath}
               localizerfn={localized} />
          <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.startstudy} visibleLower="banner" visibleUpper="screener" />
          <Banner src={{title, subtitle, startstudy, banner}} />
        </header>
        <main>
          {allSections}
          <Screener config={cfg.screener} sitedata={cfg} localizerfn={localized} />
        </main>
        {props.children}
        <Footer src={footdata} localizerfn={localized} />
      </div>
    </div>
  );
};
