/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Safe from './Safe';
import { css } from '../util/pagetools';

export default function Banner(props) {
  const cfg = props.src;
  return (
    <div id="banner" className="row banner bg-blue">
      <div className="col-xs-12 col-sm-6">
        <div id="study-flag" className="white-on-light mt-12">{cfg.banner.studyname}</div>
        <div className="banner-left-content space-y-md">
          <Safe className="banner-title text-pary-dark" type="h1" content={cfg.title} />
          <Safe className="banner-text text-pary" content={cfg.subtitle} />
          <div><Link to="#screener" className={css('get-started-btn', cfg.banner.btntheme)}>{cfg.startstudy}</Link></div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 banner-img-container">
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" className="hidden-xs" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="visible-xs" />
      </div>
    </div>
  );
};
