/**
 * BmiForm displays three fields for calculating BMI.
 * Supported props: slideIndex responder btnLabel
 */
import React, { useState } from 'react';

export default function BmiForm(props) {
  const [heightFt, setHeightFt] = useState('');
  const [heightIn, setHeightIn] = useState('');
  const [weight, setWeight] = useState('');
  const [bmi, setBmi] = useState(0);

  const hFieldFeet = {
    id: 'txt-height-feet',
    pholder: 'Height in Feet'
  }
  const hFieldInches = {
    id: 'txt-height-in',
    pholder: 'Inches'
  }
  const wField = {
    id: 'txt-weight',
    pholder: 'Weight'
  }

  function isNum(val) {
    const trimmed = (val ? val.toString().trim() : '');
    if (Boolean(trimmed)) return !isNaN(Number(trimmed));
    return false;
  }

  function fieldsOk() { return (isNum(heightFt) && isNum(weight)); }

  function onSubmit(e) {
    const bmiHandler = props.responder(props.slideIndex, bmi);
    bmiHandler(e);
  }

  function changeHandler(e) {
    if (e.target.value==='' || isNum(e.target.value)) {
      const currvalue = Number(e.target.value);
      if (e.target.name===hFieldFeet.id) {
        setHeightFt(currvalue);
        setBmi(calcBmi(currvalue, heightIn, weight));
      }
      else if (e.target.name===hFieldInches.id) {
        setHeightIn(currvalue);
        setBmi(calcBmi(heightFt, currvalue, weight));
      }
      else if (e.target.name===wField.id) {
        setWeight(currvalue);
        setBmi(calcBmi(heightFt, heightIn, currvalue));
      }
    }
  }

  function calcBmi(hFt, hIn, w) {
    const totalInches = (hFt * 12) + hIn;
    return (703 * w)/(totalInches * totalInches);
  }

  return (
    <div id="bmi-calc" className="bmi-flx">
      <div>
        <input type="text"
               name={hFieldFeet.id}
               id={hFieldFeet.id}
               placeholder={hFieldFeet.pholder}
               value={heightFt}
               className="slide-input"
               onChange={changeHandler} />
      </div>
      <div>
        <input type="text"
               name={hFieldInches.id}
               id={hFieldInches.id}
               placeholder={hFieldInches.pholder}
               value={heightIn}
               className="slide-input"
               onChange={changeHandler} />
      </div>
      <div>
        <input type="text"
               name={wField.id}
               id={wField.id}
               placeholder={wField.pholder}
               value={weight}
               className="slide-input"
               onChange={changeHandler} />
      </div>
      <div>
        <button onClick={onSubmit} disabled={!fieldsOk()} className="bmi-comp btn-block slide-btn btn-white-on-dark">{props.btnLabel}</button>
      </div>
    </div>
  );
};
