/**
 * NavToggleFlex renders a left-aligned logo and right-aligned toggle button.
 * At wide screen widths links are visible instead of the toggle button;
 * at narrow widths the button becomes visible and on click the links are
 * displayed vertically in another container.
 * Supported props: toplinks, sitename, ctx, currentpath, localizerfn, theme
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import LinkMenu from './LinkMenu';
import logo from '../images/logo.png';
import { css } from '../util/pagetools';

export default function Nav(props) {
  const locfn = props.localizerfn;
  const [open, setOpen] = useState(false);
  const vLinks = getNavLinks(props.toplinks, `nav-list`, `nav-item`, locfn, true);
  const hLinks = getNavLinks(props.toplinks, `nav-list`, `nav-item`, locfn);

  function openMenu(e) {
    const isExpanded = !open;
    let targetElem = e.target;
    if (targetElem.tagName!=='BUTTON') {
      targetElem = targetElem.closest('BUTTON');
    }
    if (isExpanded) targetElem.classList.add('open');
    else            targetElem.classList.remove('open');
    setOpen(!open);
  }

  return (
    <nav id="navbar-flx">
      <div className={css('flx posrel', props.theme)}>
        <div className="navbar-logo-container">
          <Link to={locfn('/')} title={props.sitename}>
            <img src={logo} alt={props.sitename} className="navbar-logo img-responsive" />
          </Link>
        </div>
        <div className="flx-end fg-1 pr-20">
          <div className="vis-md vcenter">{hLinks}</div>
          <button className="navbar-btn hid-md" onClick={openMenu}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top"></span>
            <span className="icon-bar middle"></span>
            <span className="icon-bar bottom"></span>
          </button>
        </div>
      </div>
      <div id="navbar-links" className={css('fg-1 navbar-links hid-md dark-on-white', (open?'open':''))}>
        {open && vLinks}
      </div>
    </nav>
  );
};

function getNavLinks(links, liststyle, itemstyle, locfn, showVertical) {
  return (
    <LinkMenu links={links}
              listStyle={liststyle}
              itemStyle={css(itemstyle, (showVertical?'vertical':''))}
              localizerfn={locfn} />
  );
}
